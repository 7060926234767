import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import {
  MAT_DIALOG_DEFAULT_OPTIONS,
  MatDialogConfig,
} from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HotToastModule } from '@ngneat/hot-toast';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsDevelopmentModule, NgxsModule } from '@ngxs/store';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { TimeagoModule } from 'ngx-timeago';
import { environment } from '../environments/environment';
import { CompanyHolidaysConfigState } from './admin/state/company-holidays-config.state';

import { CdkScrollableModule } from '@angular/cdk/scrolling';
import { MatNativeDateModule } from '@angular/material/core';
import {
  popperVariation,
  provideTippyConfig,
  tooltipVariation,
} from '@ngneat/helipopper';
import { AdminFilesState } from './admin/state/admin-files.state';
import { AdminJobsState } from './admin/state/admin-jobs.state';
import { CompanyTextState } from './admin/state/company-text.state';
import { EmployeesState } from './admin/state/employees.state';
import { ProcessingLogsState } from './admin/state/processing-logs.state';
import { UserLogsState } from './admin/state/user-logs.state';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthState } from './auth/auth.state';
import { BenefitsConfigState } from './benefits/state/benefits-config.state';
import { BenefitsState } from './benefits/state/benefits.state';
import { GlobalWorkLifeBenefitsState } from './benefits/state/global-work-life-benefits.state';
import { AdminChangeRequestsState } from './change-approval/state/admin-change-requests.state';
import { CompensationConfigState } from './compensation/state/compensation-config.state';
import { CompensationState } from './compensation/state/compensation.state';
import { ContactsState } from './contacts/state/contacts.state';
import { CoreModule } from './core/core.module';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { CompanyState } from './core/state/company.state';
import { PaletteState } from './core/state/palette.state';
import { GlobalDateRangeState } from './global-date-range.state';
import { AddressState } from './manage-my-information/state/address.state';
import { ChangeRequestState } from './manage-my-information/state/change-request.state';
import { DependentState } from './manage-my-information/state/dependent.state';
import { EmergencyContactState } from './manage-my-information/state/emergency-contact.state';
import { PaymentInformationState } from './manage-my-information/state/payment-information.state';
import { PersonalInformationState } from './manage-my-information/state/personal-information.state';
import { NewsState } from './news/state/news.state';
import { OverviewState } from './overview/state/overview.state';
import { CompanyHolidayState } from './paid-time-off/state/company-holiday.state';
import { PaidTimeOffConfigState } from './paid-time-off/state/paid-time-off-config.state';
import { PaidTimeOffState } from './paid-time-off/state/paid-time-off.state';
import { PayslipState } from './payslip/state/payslip.state';
import { ReportingState } from './reporting/state/reporting.state';
import { TaxFormsState } from './tax-forms/state/tax-forms.state';
import { WelcomeState } from './welcome/state/welcome.state';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,

    CoreModule,

    CdkScrollableModule,

    NgxsModule.forRoot(
      [
        AuthState,
        EmployeesState,
        BenefitsState,
        BenefitsConfigState,
        ContactsState,
        CompensationState,
        CompensationConfigState,
        PaidTimeOffState,
        PaidTimeOffConfigState,
        CompanyTextState,
        OverviewState,
        CompanyState,
        PaletteState,
        GlobalWorkLifeBenefitsState,
        NewsState,
        PayslipState,
        TaxFormsState,
        ProcessingLogsState,
        ReportingState,
        ChangeRequestState,
        DependentState,
        AdminChangeRequestsState,
        PaymentInformationState,
        EmergencyContactState,
        AddressState,
        PersonalInformationState,
        WelcomeState,
        CompanyHolidaysConfigState,
        CompanyHolidayState,
        UserLogsState,
        GlobalDateRangeState,
        AdminJobsState,
        AdminFilesState,
      ],
      {
        developmentMode: !environment.production,
        selectorOptions: {
          suppressErrors: false,
        },
      },
    ),
    NgxsDevelopmentModule.forRoot({
      warnOnUnhandledActions: true,
    }),
    // NgxsLoggerPluginModule.forRoot({}),
    NgxsStoragePluginModule.forRoot({
      keys: ['auth.refreshToken', 'auth.accessToken'],
    }),
    NgxsReduxDevtoolsPluginModule.forRoot(),

    HotToastModule.forRoot({
      position: 'top-right',
      loading: {
        className: 'toast-loading',
      },
      success: {
        className: 'toast-success',
      },
      error: {
        className: 'toast-error',
      },
    }),

    TimeagoModule.forRoot({}),

    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),

    MatNativeDateModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline',
        floatLabel: 'always',
      },
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        maxWidth: '90vw',
        width: '640px',
      } as MatDialogConfig,
    },

    provideHttpClient(withInterceptorsFromDi()),

    provideTippyConfig({
      defaultVariation: 'tooltip',
      variations: {
        tooltip: {
          ...tooltipVariation,
          animation: 'shift-away',
          arrow: true,
          appendTo: () => document.body,
        },
        popper: popperVariation,
        darkPopper: {
          ...popperVariation,
          theme: 'dark',
          arrow: false,
        },
      },
    }),

    provideEnvironmentNgxMask(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
